<template>
	<div>
		<v-app class="project-container" id="customers">
			<v-main v-cloak>
				<loading-data
					v-if="!userStore.IsLoggedIn"
					:loadingDataText="
						!userStore.IsLoggedIn ? 'Please Login Using the Popup Provided' : 'Logging In...'
					"
					:project="'Customers'"
				>
				</loading-data>

				<router-view v-else style="z-index: 25" />
			</v-main>
		</v-app>
	</div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { NoData, LoadingData } from '@gz/styleguide';
import { userStore } from './store/user/userPinia';

@Component({
	components: {
		NoData,
		LoadingData,
	},
})
export default class App extends Vue {
	userStore = userStore();

	created() {
		this.userStore.InitialiseSubscriptions();
		const theme = localStorage.getItem('dark_theme');
		if (theme) {
			if (theme === 'true') {
				this.$vuetify.theme.dark = true;
			} else {
				this.$vuetify.theme.dark = false;
			}
		} else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			this.$vuetify.theme.dark = true;
			localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString());
		}
	}
}
</script>

<style scoped lang="scss">
.project-container {
	// padding-left: 10px;
	// margin-left: 200px;
}

.internal-container {
	height: 100%;
}
</style>

<style lang="scss">
@import './styles/base-styles.scss';

.sidebar-container {
	/* margin-top: 64px !important; */
}

.internal-container {
	@include devices(tablet) {
		overflow-y: scroll;
		overflow-x: hidden;
	}

	@include devices(mobile) {
	}
}

.layout-container {
	margin-left: 56px;
	height: calc(100% - var(--header-bar-height));

	@include devices(tablet) {
		margin-left: 0;
		// height: auto;
		// overflow: hidden;
	}

	@include devices(mobile) {
	}
}

/* width */
::-webkit-scrollbar {
	width: 11px;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	/* background: var(--v-primary-darken2); */
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
</style>
