import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		options: { customProperties: true },
		themes: {
			light: {
				primary: colors.cyan.base,
				secondary: '#673AB7',
				tertiary: colors.orange.base,
				accent: '#8c9eff',
				error: '#D41116',
				warning: '#F59D1D',
				grey: '#808080',
				root: '#e2e2e2',
				white: '#ffffff',
				green: '#50A82A',
			},
			dark: {
				root: '#121212',
				white: '#121212',
				error: '#D41116',
				warning: '#F59D1D',
				grey: '#808080',
				green: '#50A82A',
			},
		},
	},
});
