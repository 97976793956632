import { ApplicationInsights } from '@microsoft/applicationinsights-web';

/**
 * Install function passed to Vue.use() show documentation on vue.js website.
 *
 * @param Vue
 * @param options
 */
function install(Vue: any, options: any) {
	const config = options.appInsightsConfig || {};

	const initialiseLoggedInUser = (envelope: any) => {
		envelope.data.LoggedInUser = options.loggedInUser;
	};

	if (options.appInsights) {
		options.appInsights.addTelemetryInitializer(initialiseLoggedInUser);
		Vue.appInsights = options.appInsights;
	} else {
		Vue.appInsights = new ApplicationInsights({ config });

		Vue.appInsights.loadAppInsights();
		Vue.appInsights.addTelemetryInitializer(initialiseLoggedInUser);

		if (typeof options.onAfterScriptLoaded === 'function') {
			options.onAfterScriptLoaded(Vue.appInsights);
		}
	}

	const router = options.router;

	// Watch route event if router option is defined.
	if (router) {
		if (options.trackInitialPageView !== false) {
			setupPageTracking(options, Vue);
		} else {
			router.onReady(() => setupPageTracking(options, Vue));
		}
	}

	if (Vue.prototype.$appInsights) {
		Vue.prototype.$appInsights = Vue.appInsights;
	} else {
		Object.defineProperty(Vue.prototype, '$appInsights', {
			get: () => Vue.appInsights,
			set: (val: any) => {
				Vue.appInsights = val;
			},
		});
	}
}

/**
 * Track route changes as page views with AppInsights
 * @param options
 */
function setupPageTracking(options: any, Vue: any) {
	const router = options.router;

	const baseName = options.baseName || '(Vue App)';

	router.beforeEach((route: any, from: any, next: any) => {
		if (!route.name) {
			next();
			return;
		}

		const name = baseName + ' / ' + route.name;
		Vue.appInsights.startTrackPage(name);
		next();
	});

	router.afterEach((route: any) => {
		if (!route.name) {
			return;
		}

		const name = baseName + ' / ' + route.name;
		const url = location.protocol + '//' + location.host + route.fullPath;
		Vue.appInsights.stopTrackPage(name, url);
		Vue.appInsights.flush();
	});
}

// auto install for navigator
if (typeof window !== 'undefined' && window.Vue) {
	window.Vue.use(install);
}

export default install;
